<template>
  <div
    :class="{ 'col-3': heading }"
    class="aside"
  >
    <div class="mail--sidebar">
      <div
        v-if="heading"
        class="mail--heading"
      >
        <h3 class="mail--heading__h">
          {{ $t('actionPages.titles.mail') }}
          <span
            v-show="sex.isMan ? counters.mails_inbox_new : counters.mails_inbox_new_not_reply"
          >({{ sex.isMan ? counters.mails_inbox_new : counters.mails_inbox_new_not_reply }})</span>
        </h3>
      </div>
      <rb-button
        v-if="heading"
        class="btn-black"
        @click.native="toggleMailModal(false)"
      >
        <span
          v-if="heading"
          class="d-flex align-items-center set-flex-x-center"
        >
          <span class="rbi rbi-edit" />
          <span>{{ $t('mail.utils.write') }}</span>
        </span>
      </rb-button>
      <tabs
        cache-lifetime="0"
        :options="{ useUrlFragment: false }"
      >
        <tab
          :id="tabID('all')"
          :name="titleAll"
        >
          <ul class="list-unstyled mail--sidebar__menu">
            <router-link
              tag="li"
              :to="{ name: 'mailbox' }"
              exact
              active-class="active"
              @click.native="close"
            >
              <a class="d-flex align-items-center">
                <span class="rbi rbi-mail-envelope-closed" />
                <span
                  class="text"
                >{{ $t('mail.menu.inbox') }}
                  <span
                    v-show="
                      sex.isMan ? counters.mails_inbox_new : counters.mails_inbox_new_not_reply
                    "
                  >({{
                    sex.isMan ? counters.mails_inbox_new : counters.mails_inbox_new_not_reply
                  }})</span></span>
              </a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'mailbox', query: { view: 'sent', in: 'all' } }"
              exact
              active-class="active"
              @click.native="close"
            >
              <a class="d-flex align-items-center">
                <span class="rbi rbi-mail-checked" />
                <span class="text">{{ $t('mail.menu.sent') }}</span>
              </a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'mailbox', query: { view: 'dialog', in: 'all' } }"
              exact
              active-class="active"
              @click.native="close"
            >
              <a class="d-flex align-items-center">
                <span class="rbi rbi-chat" />
                <span
                  class="text"
                >{{ $t('mail.menu.dialogs') }}
                  <span
                    v-show="counters.mails_dialogs_all"
                  >({{ counters.mails_dialogs_all }})</span></span>
              </a>
            </router-link>
          </ul>
        </tab>

        <tab
          :id="tabID('bookmarks')"
          :name="titleBookmarks"
        >
          <ul class="list-unstyled mail--sidebar__menu">
            <router-link
              tag="li"
              :to="{ name: 'mailbox', query: { view: 'inbox', in: 'bookmarks' } }"
              exact
              active-class="active"
              @click.native="close"
            >
              <a class="d-flex align-items-center">
                <span class="rbi rbi-mail-envelope-closed" />

                <span
                  class="text"
                >{{ $t('mail.menu.inbox') }}
                  <span
                    v-show="
                      sex.isMan
                        ? counters.mails_inbox_new_bookmarks
                        : counters.mails_inbox_new_bookmarks_not_reply
                    "
                  >({{
                    sex.isMan
                      ? counters.mails_inbox_new_bookmarks
                      : counters.mails_inbox_new_bookmarks_not_reply
                  }})</span></span>
              </a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'mailbox', query: { view: 'sent', in: 'bookmarks' } }"
              exact
              active-class="active"
              @click.native="close"
            >
              <a class="d-flex align-items-center">
                <span class="rbi rbi-mail-checked" />

                <span class="text">{{ $t('mail.menu.sent') }}</span>
              </a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'mailbox', query: { view: 'dialog', in: 'bookmarks' } }"
              exact
              active-class="active"
              @click.native="close"
            >
              <a class="d-flex align-items-center">
                <span class="rbi rbi-chat" />

                <span
                  class="text"
                >{{ $t('mail.menu.dialogs') }}
                  <span
                    v-show="counters.mails_dialogs_bookmarks"
                  >({{ counters.mails_dialogs_bookmarks }})</span></span>
              </a>
            </router-link>
          </ul>
        </tab>
        <tab
          :id="tabID('favorites')"
          :name="titleFavs"
        >
          <ul class="list-unstyled mail--sidebar__menu">
            <router-link
              tag="li"
              :to="{ name: 'mailbox', query: { view: 'inbox', in: 'favorites' } }"
              exact
              active-class="active"
              @click.native="close"
            >
              <a class="d-flex align-items-center">
                <span class="rbi rbi-mail-envelope-closed" />

                <span
                  class="text"
                >{{ $t('mail.menu.inbox') }}
                  <span
                    v-show="
                      sex.isMan
                        ? counters.mails_inbox_new_favorite_accept
                        : counters.mails_inbox_new_favorite_accept_not_reply
                    "
                  >({{
                    sex.isMan
                      ? counters.mails_inbox_new_favorite_accept
                      : counters.mails_inbox_new_favorite_accept_not_reply
                  }})</span></span>
              </a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'mailbox', query: { view: 'sent', in: 'favorites' } }"
              exact
              active-class="active"
              @click.native="close"
            >
              <a class="set-flex set-flex-y-center">
                <span class="rbi rbi-mail-checked" />

                <span class="text">{{ $t('mail.menu.sent') }}</span>
              </a>
            </router-link>
            <router-link
              tag="li"
              :to="{ name: 'mailbox', query: { view: 'dialog', in: 'favorites' } }"
              exact
              active-class="active"
              @click.native="close"
            >
              <a class="set-flex set-flex-y-center">
                <span class="rbi rbi-chat" />

                <span
                  class="text"
                >{{ $t('mail.menu.dialogs') }}
                  <span
                    v-show="counters.mails_dialogs_favorite_accept"
                  >({{ counters.mails_dialogs_favorite_accept }})</span></span>
              </a>
            </router-link>
          </ul>
        </tab>
      </tabs>
      <template v-if="sex.isWoman">
        <rb-button
          class="btn-red"
          type="button"
          :title="$t('alerts.notEnoughTrust')"
          @click.native="tmpDisabledOpen"
        >
          <span class="set-flex set-flex-y-center set-flex-x-center">
            <span class="rbi rbi-rss" />
            <span>{{ $t('mail.utils.dispatch') }}</span>
          </span>
        </rb-button>
        <template v-if="false">
          <!--disabled page-->
          <router-link
            tag="div"
            :to="{ name: 'mail-invitations' }"
            class="mail--sidebar__menu"
          >
            <a><span class="text">{{ $t('mail.utils.dispCreated') }}</span>
              <span
                v-show="counters.mails_distribution_all"
              >({{ counters.mails_distribution_all }})</span></a>
          </router-link>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { MAIL_MODAL, DISPATCH_MODAL } from '../../../mixins/formsActions';
import RbButton from '../../Button';

export default {
  components: {
    RbButton,
  },
  mixins: [MAIL_MODAL, DISPATCH_MODAL],
  props: {
    tabSuffix: String,
    heading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    titleAll() {
      return `<span>${this.$t('mail.utils.all')}</span>`;
    },
    titleFavs() {
      return `<span class="rbi rbi-heart"></span>`;
    },
    titleBookmarks() {
      return `<span class="rbi rbi-star"></span>`;
    },
    sex() {
      return this.$store.getters.sex;
    },
    counters() {
      return this.$store.getters.counters;
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    /**
     * Временно отключить ссылку с рассылками писем
     */
    tmpDisabledOpen() {
      // was toggleDispatchModal(), remove :title too!
      this.$store.commit('addAlert', {
        type: 'error',
        text: this.$t('alerts.notEnoughTrust'),
      });
    },
    /**
     * Отформатировать ID табов для использования в респонзиве/десктопе
     * @param id {string}
     * @return {string}
     */
    tabID(id) {
      return this.tabSuffix ? id + this.tabSuffix : id;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  font-size: 12px !important;
}
.btn-red {
  width: 100%;
  height: auto;
  padding: 13px 30px !important;
}
</style>
