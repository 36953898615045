<template>
  <div>
    <div class="mail--box__header d-flex align-items-center">
      <!-- <div class="d-flex single">
         <div class="d-flex mail&#45;&#45;responsive-nav">
           <responsive-menu class="menu hidden-lg"></responsive-menu>
           <button @click="goBack" class="btn btn-mail" type="button">
                         <span class="d-flex align-items-center set-flex-x-center">
                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400.004 400.004"><path
               d="M382.688 182.686H59.116l77.21-77.214c6.763-6.76 6.763-17.726 0-24.485-6.765-6.764-17.73-6.764-24.485 0L5.074 187.757c-6.764 6.76-6.764 17.727 0 24.485L111.84 319.017c3.382 3.383 7.813 5.072 12.243 5.072 4.43 0 8.86-1.69 12.242-5.073 6.764-6.76 6.764-17.726 0-24.484l-77.21-77.218H382.69c9.562 0 17.316-7.753 17.316-17.315 0-9.562-7.753-17.314-17.316-17.314z"/></svg>
                         <span>{{ $t( 'mail.utils.back' ) }}</span>
                     </span>
           </button>
         </div>
         <button class="btn btn-gray btn-mail btn-del" type="button" @click="delMail" v-if="!isDialog">
                     <span class="d-flex align-items-center set-flex-x-center">
                         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.6 489.6">
                             <path
                 d="M456.5 75.6h-103V51.5C353.5 23.1 330.4 0 302 0H187.6c-28.4 0-51.5 23.1-51.5 51.5v24.1h-103c-9.9 0-18 8.1-18 18s8.1 18 18 18h27.4v315.2c0 34.6 28.1 62.8 62.8 62.8h243.1c34.6 0 62.8-28.1 62.8-62.8V169.2c0-9.9-8.1-18-18-18s-18 8.1-18 18v257.6c0 14.8-12 26.8-26.8 26.8H123.2c-14.8 0-26.8-12-26.8-26.8V111.6h360c10 0 18.1-8.1 18.1-18s-8.1-18-18-18zm-138.9 0H172.1V51.5c0-8.5 6.9-15.5 15.5-15.5h114.5c8.5 0 15.5 6.9 15.5 15.5v24.1z"/>
                             <path
                 d="M244.8 151.2c-9.9 0-18 8.1-18 18V396c0 9.9 8.1 18 18 18s18-8.1 18-18V169.2c0-9.9-8.1-18-18-18zM169.2 151.2c-9.9 0-18 8.1-18 18V396c0 9.9 8.1 18 18 18s18-8.1 18-18V169.2c0-9.9-8.1-18-18-18zM320.4 151.2c-9.9 0-18 8.1-18 18V396c0 9.9 8.1 18 18 18s18-8.1 18-18V169.2c0-9.9-8.1-18-18-18z"/>
                         </svg>
                     </span>
         </button>
       </div>-->
      <div class="action-page--heading mail-open">
        <div class="d-flex set-flex-x-between head-wrap">
          <h1 class="action-page--heading__h d-lg-none d-sm-flex d-flex" @click="toogleMenu">
            <span>{{ $t('actionPages.titles.mail') }}</span>
            <span
              v-show="sex.isMan ? counters.mails_inbox_new : counters.mails_inbox_new_not_reply"
              class="count"
              >({{
                sex.isMan ? counters.mails_inbox_new : counters.mails_inbox_new_not_reply
              }})</span
            >
            <mail-sidebar
              v-if="menuOpen"
              v-click-away="closeMenu"
              :heading="false"
              @click.native.stop
            />
          </h1>
          <div class="action-page--tip">
            <button class="btn btn-mail" type="button" @click="goBack">
              <span class="d-flex align-items-center set-flex-x-center">
                <span class="rbi rbi-left-arrow" />
                <span>{{ $t('mail.utils.back') }}</span>
              </span>
            </button>
            <button v-if="!isDialog" class="btn-del" type="button" @click="delMail">
              <span class="d-flex align-items-center set-flex-x-center">
                <span class="rbi rbi-trash" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-show="showPreloader || !showForm" class="loader--wrap">
      <div class="loader" />
    </div>
    <template v-if="(letter.message.id || dialogs.length) && !showPreloader">
      <mail-inside-item
        v-if="!isDialog"
        class="detailed"
        :self-user="selfUser.user_id"
        :letter="letter"
      />
      <div
        v-if="isDialog"
        ref="simplebar"
        class="simplebar dialog_scroll"
        :style="{ height: height }"
      >
        <div ref="dialogs" class="dialog-content">
          <div v-show="showLoadMore && !showListLoader" class="button-wrap">
            <load-more :counter="defaultLimit" @click.native="loadMore($event)" />
          </div>
          <div v-show="showListLoader" class="loader--wrap">
            <div class="loader" />
          </div>
          <mail-inside-item
            v-for="item in dialogs"
            :key="item.message.id"
            :is-dialog="true"
            class="dialog-item"
            :self-user="selfUser.user_id"
            :class="{ unread: isLetterUnreaded(item) }"
            :is-unread="isLetterUnreaded(item)"
            :open="open(item)"
            :can-answer="!answeringLetter.letter"
            :letter="item"
            @select2Answer="answeringLetter = $event"
            @cancelAnswer="answeringLetter = {}"
          />
        </div>
      </div>
      <mail-inside-item-response v-if="letter.message_reply" :letter="letter" />
      <send-letter-form
        v-if="!letter.message_reply && !sentboxMail"
        ref="form"
        :style="{ opacity: showForm }"
        :woman-can-answer="womanCanAnswer"
        :companion-id="letter.sender.user_id || answeringLetter.receiverID || msgOrUserID"
        :is-dialog="isDialog"
        :msg-or-user-i-d="msgOrUserID"
        @reply="askDetailedGeneral(!$event)"
      />
    </template>
    <btn-mail
      v-if="!letter.message_reply && !sentboxMail"
      :user="userForResponsiveBtn"
      :woman-can-answer="womanCanAnswer"
      :is-answer="sex.isWoman && womanCanAnswer"
      :data="{ targetMessage: msgOrUserID }"
    />
  </div>
</template>

<script>
import 'simplebar/dist/simplebar.css';

import orderBy from 'lodash/orderBy';
import SimpleBar from 'simplebar';
import { DELETE_MAILS, MARK_READ } from '../../../mixins/types/mail';
import { PREPARE_DATA } from '../../../mixins/userActions';
import LoadMore from '../parts/LoadMore.vue';
import MailInsideItem from './MailInsideItem.vue';
import MailInsideItemResponse from './MailInsideItemResponse.vue';
import ResponsiveWriteMailBtn from './responsive/ResponsiveWriteMailBtn.vue';
import SendLetterForm from './SendLetterForm.vue';
import MailSidebar from './MailSidebar.vue';

export default {
  components: {
    MailSidebar,
    MailInsideItem,
    SendLetterForm,
    btnMail: ResponsiveWriteMailBtn,
    MailInsideItemResponse,
    LoadMore,
  },
  mixins: [DELETE_MAILS, MARK_READ, PREPARE_DATA],
  props: {
    isDialog: Boolean,
  },
  data() {
    return {
      count: 0,
      offset: 0,
      defaultLimit: 20,
      limit: 20,
      showPreloader: true,
      showListLoader: false,
      menuOpen: false,
      showForm: 0,
      letter: {
        sender: {
          avatar: {},
        },
        message: {},
        attachment: [],
        disabled: {},
      },
      dialogs: [],
      clearLetter: {},
      scroll: null,
      height: 0,
      answeringLetter: {}, // { letter: {}, el: {} }
      scrollBool: false,
      scrollCount: 0,
    };
  },
  computed: {
    // dialogs() {
    //   return this.$store.getters.dialogs;
    // },
    //
    // searchLimited() {
    //   return this.searchResults.slice( 0, this.limit );
    // },
    //
    // /**
    //  * Получить ограниченное кол-во закладок
    //  *
    //  * @return {array}
    //  */
    // limitedDialogs() {
    //   return this.dialogs.slice( 0, this.defaultLimit );
    // },
    //
    // /**
    //  * Выбрать какой именно список мы сейчас показываем: поиск или обычный
    //  * @return {array}
    //  */
    // displayedList() {
    //   return this.isSearching ? this.searchLimited.reverse() : this.limitedDialogs.reverse();
    // },

    /**
     * Вычисляет, нужно ли показывать кнопку удаления письма пользователя
     *
     * @return {boolean}
     */
    canDeleteMail() {
      if (this.sex.isMan) {
        return !this.isDialog;
      }
      if (this.sex.isWoman) {
        return this.sentboxMail ? this.sentboxMail : !this.isDialog && this.letter.message_reply;
      }
      return false;
    },
    /**
     * Вычислет, может ли клиентка ответить на письмо
     * @return {boolean}
     */
    womanCanAnswer() {
      // women dialogs behavior
      if (this.sex.isWoman && this.isDialog && !!this.answeringLetter.letter) {
        return true;
      }
      if (!this.isDialog) {
        return true;
      }
      return false;
    },

    /**
     * Возвращает ИД письма, на которое отвечает пользователь
     *
     * @return {number|undefined}
     */
    answLetterID() {
      if (this.answeringLetter.letter && this.answeringLetter.letter.message) {
        return this.answeringLetter.letter.message.id;
      }
      return undefined;
    },

    /**
     * Проверяет в какой вкладке находится пользователь
     * @return {boolean}
     */
    sentboxMail() {
      return this.selfUser.user_id === this.letter.sender.user_id;
    },
    width() {
      return this.$store.getters.deviceWidth;
    },
    user() {
      return this.$store.getters.user;
    },
    sex() {
      return this.$store.getters.sex;
    },
    selfUser() {
      return this.$store.getters.user;
    },

    counters() {
      return this.$store.getters.counters;
    },

    /**
     * Возвращает ИД письма или пользователя
     *
     * @return {number|boolean}
     */
    msgOrUserID() {
      if (this.sex.isWoman) {
        return this.letter.message.id || this.answLetterID || +this.$route.params.letter;
      }
      if (this.sex.isMan) {
        return this.isDialog ? +this.$route.params.letter : this.addressee;
      }
      return false;
    },

    /**
     * Проверяет, показыть ли кнопку "загрузить больше"
     * @return {boolean}
     */
    showLoadMore() {
      return this.dialogs.length < this.count;
    },

    /**
     * Возвращает ИД получателя письма
     *
     * @return {number}
     */
    addressee() {
      // choose sender || receiver user_id depends on sent || inbox || dialog view
      return this.letter.message.sender_user_id == this.selfUser.user_id
        ? this.letter.message.receiver_user_id
        : this.letter.message.sender_user_id;
    },

    /**
     * Вовзвращает ИД пользователя для респонзив кнпоки ответа на письма
     * @return {*}
     */
    userForResponsiveBtn() {
      if (this.isDialog) {
        return +this.$route.params.letter; // id of user dialog with
      }
      return this.addressee;
    },
  },
  watch: {
    /**
     * Очистить данные при смене роута
     */
    $route() {
      this.showForm = 0;
      this.answeringLetter = {};
    },

    /**
     * Спросить письмо при получении данных профиля
     */
    sex() {
      if (!this.letter.sender.user_id && !this.dialogs.length) {
        this.askDetailedGeneral();
      }
    },

    /**
     * Запустить проверку привилегий и создание скроллбара при получении списка писем (для диалога)
     *
     * @param v {array}
     * @param old {array, undefined}
     */
    dialogs(v, old) {
      this.extendedCheck(old, []);
      this.makeScrollbar();
    },

    /**
     * Проверить привилегии при получении письма
     */
    clearLetter(v) {
      if (!this.usersUpdated) {
        this.singleArrayCheckPrivileges(v, 'user_id').then((response) => {
          this.letter = response;
        });
      }
    },
  },
  mounted() {
    this.showPreloader = true;
    if (!this.isDialog) {
      this.showForm = 1;
    }
    this.$store.dispatch('getCounters', {});
  },
  methods: {
    toogleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },

    /**
     * Открыть для детального просмотра последнее письмо
     *
     * @param item {object}
     */
    open(item) {
      if (item.message.id === this.dialogs[this.dialogs.length - 1].message.id) {
        return true;
      }
      return false;
    },

    /**
     * Посчитать высоту контейнера для скроллбара (в диалогах)
     */
    calcHeight() {
      this.showForm = 1;
      const self = this;
      if (this.width > 1200 && !this.letter.message_reply) {
        setTimeout(() => {
          self.scroll = new SimpleBar(self.$refs.simplebar, {
            wrapContent: false,
          });
        }, 1500);
        setTimeout(() => {
          //   self.scroll = new SimpleBar(self.$refs.simplebar, {
          //     wrapContent: false
          //   });
          if (document.getElementById('mail-form')) {
            const footerH = document.getElementById('footer').offsetHeight;
            const mailFormH = document.getElementById('mail-form').offsetHeight;
            const navbarH = document.querySelector('.header').offsetHeight;
            const bodyH = document.body.offsetHeight;
            this.height = `${bodyH - (footerH + mailFormH + navbarH + 190)}px`;
          }
        }, 500);
      }
    },

    /**
     * Сделать кастомный скроллбар
     */
    makeScrollbar() {
      if (this.width > 1200 && !this.letter.message_reply) {
        const self = this;
        setTimeout(() => {
          if (!self.scroll) {
            self.scroll = new SimpleBar(self.$refs.simplebar, {
              wrapContent: false,
              autoHide: false,
            });
            const container = self.scroll.getScrollElement();
            const el = self.$refs.dialogs.lastElementChild;
            self.$scrollTo(el, 1000, {
              container,
              offset: 0,
            });
          }
        }, 100);
        if (!this.scroll) {
          this.calcHeight();
        } else {
          this.updateScroll();
        }
      } else {
        this.height = 'auto';
      }
    },

    /**
     * Обновить состояние скроллбара
     */
    updateScroll() {
      this.showForm = 1;
      if (this.scroll && this.scrollBool && !this.scrollCount) {
        // this.showPreloader = false;
        this.scroll.getContentElement();
        // this.scroll.recalculate();
        const container = this.scroll.getScrollElement();
        const el = this.$refs.dialogs.lastElementChild;
        this.$scrollTo(el, 300, {
          container,
          offset: 0,
        });
        this.scrollCount++;
      } else if (!this.scrollBool && this.scrollCount) {
        this.scroll.getContentElement();
        this.scroll.recalculate();
        const container = this.scroll.getScrollElement();
        const el = this.scroll.getScrollElement().childNodes[0];
        this.$scrollTo(el, {
          container,
          offset: 0,
        });
      }
    },

    /**
     * Удалить письмо, если есть на это привилегии
     */
    delMail() {
      if (this.canDeleteMail) {
        const self = this;
        this.mailDelete(this.letter.message.id).then(() => {
          self.goBack();
        });
      } else {
        this.$store.commit('addAlert', {
          type: 'error',
          text: this.$t('alerts.cantDelUnAnswMail'),
        });
      }
    },

    /**
     * Вернуться по истории браузера на 1 шаг назад
     */
    goBack() {
      this.$router.go(-1);
    },

    /**
     * Загрузить все данные о письме
     */
    askDetailedMail() {
      this.$http
        .post('v1/message/detail', {
          access_token: window.localStorage['access-token'],
          message_id: this.$route.params.letter,
        })
        .then((response) => {
          try {
            const tmp = response.body.result;
            tmp.user_id = tmp.sender.user_id;
            this.clearLetter = tmp;
          } catch (e) {
            console.error(e);
          }
          this.usersUpdated = false;
          this.showPreloader = false;
        });
    },

    /**
     * Загрузить все данные с диалога
     * @param emitted {boolean} - если это было симитировано после ответа на письмо
     */

    // askDetailedDialog(emitted) {
    //   this.showPreloader = true;
    //   this.clearUpdate();
    //   this.$http.post( 'v1/message/load/dialog/one', {
    //     access_token: window.localStorage[ 'access-token' ],
    //     target_user_id: this.$route.params.letter,
    //     offset: this.offset,
    //     limit: this.defaultLimit
    //   } ).then( response => {
    //     this.clearUpdate();
    //     this.showPreloader = false;
    //     this.count = +response.body.count;
    //     let result = response.body.result;
    //     if ( !emitted ) {
    //               result.forEach( i => {
    //                 this.selfUser.user_id !== i.companion.user_id ? i.user_id = i.companion.user_id : '';
    //               }, this )
    //             }
    //       let IDs = result.filter( i => {
    //                 return !i.message.time_open && (i.message.sender_user_id !== this.user.user_id)
    //               }, this ).map( i => i.message.id );
    //               if ( IDs.length ) this.markAsRead( IDs );
    //     this.$store.commit( 'updateDialogs', {
    //       offset: 0,
    //       data: response.body.result
    //     } )
    //   }, error => {
    //     console.log( error )
    //   } )
    // },

    askDetailedDialog(emitted) {
      if (this.dialogs < 0) {
        this.showListLoader = false;
      }
      let tmp = [];
      !emitted ? (this.offset = 0) : this.offset;

      if (!emitted) {
        this.offset = 0;
        this.scrollCount = 0;
        this.scrollBool = true;
        this.showPreloader = false;
        this.showListLoader = false;
      } else {
        this.offset;
        this.showListLoader = true;
      }
      // this.showListLoader = true;
      // this.scrollCount = 0
      // this.scrollBool = true;
      this.$http
        .post('v1/message/load/dialog/one', {
          access_token: window.localStorage['access-token'],
          target_user_id: this.$route.params.letter,
          offset: this.offset,
          limit: this.limit,
        })
        .then((response) => {
          if (response.body.status) {
            this.count = +response.body.count;
            const { result } = response.body;
            result.forEach((i) => {
              this.selfUser.user_id !== i.companion.user_id
                ? (i.user_id = i.companion.user_id)
                : '';
            }, this);
            if (!emitted) {
              this.dialogs = response.body.result.forEach((item) => tmp.push(item));
            } else {
              tmp = this.dialogs.concat(response.body.result);
            }
            // let IDs = result.filter( i => {
            //   return !i.message.time_open && (i.message.sender_user_id !== this.user.user_id)
            // }, this ).map( i => i.message.id );
            // console.log(IDs)
            // if ( IDs.length ) this.markAsRead( IDs );
            this.showPreloader = false;
            this.showForm = 1;
            // let tmp = []
            // let tmp = response.body.result.forEach( item => this.dialogs.push( item ) )

            // let tmp = response.body.result
            // let tmp =  this.dialogs.concat( response.body.result )
            this.showListLoader = false;
            this.dialogs = orderBy(tmp, (e) => e.message.time_create, ['asc']);
            const self = this;
            setTimeout(() => {
              if (self.width <= 1200 && !self.offset) {
                const el = self.$refs.dialogs.lastElementChild;
                self.$scrollTo(el);
              }
            }, 500);
          }
        });
    },

    /**
     * Общая фукнция для загрузки письма. Вычисляет, что нужно загружать: письмо или диалог
     * @param emitted {boolean} - если это было симитировано после ответа на письмо
     */
    askDetailedGeneral(emitted) {
      this.answeringLetter = {};
      if (emitted && this.sex.isMan) {
        this.askDetailedDialog(emitted);
        return;
      }
      this.isDialog ? this.askDetailedDialog(emitted) : this.askDetailedMail(emitted);
    },

    /**
     * Ленивая подгрузка
     */
    loadMore() {
      this.scrollBool = false;
      this.offset += this.defaultLimit;
      this.askDetailedDialog(true);
    },

    /**
     * Проверяет, является письмо непрочитанным
     * @param letter {object}
     * @return {boolean}
     */
    isLetterUnreaded(letter) {
      return (
        !letter.message.replied_message_id &&
        this.sex.isWoman &&
        letter.message.sender_user_id !== this.user.user_id
      );
    },
    /* canAnswer( item ) {
                return !answeringLetter.letter
            } */
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('updateGallery', {
      open: false,
      index: 0,
      hideWatermark: false,
      photos: [],
    });
    next();
  },
};
</script>

<style>
.dialog_scroll {
  height: 300px;
}
</style>
