<template>
  <div
    class="mail--box__body--single"
    :class="{ detailed: detailedMessage, selected: letter.answering }"
    @click="openItem($event.target)"
  >
    <div class="mail--box__body--single__inner clearfix">
      <router-link
        tag="div"
        :to="{ name: 'profile', params: { id: user.user_id } }"
        class="ava-box non-click"
      >
        <div class="ava-wrap">
          <img
            :src="setPhotoSource(user.avatar.img_medium)"
            :alt="user.name + $t('shared.alts.ava')"
          />
          <span v-if="user.online" class="online" />
          <span
            v-if="userWebcam(user.camera).status"
            class="webcam rbi-webcam"
            :class="{ hd: userWebcam(user.info.camera).hd }"
          />
          <span v-if="user.new" class="is-new" />
        </div>
      </router-link>
      <div class="content">
        <div class="msg-top">
          <div class="d-flex align-items-start justify-content-between">
            <div class="set-flex">
              <div class="user-info">
                <span class="uid hidden-xs">ID: {{ user.user_id }}</span>
                <router-link
                  tag="span"
                  :to="{ name: 'profile', params: { id: user.user_id } }"
                  class="name non-click"
                >
                  {{ user.name
                  }}<template v-if="user.age !== null"> , {{ getUserAge(user.age) }} </template>
                  <span
                    v-if="letter.attachments.length && !detailed && isDialog"
                    class="rbi rbi-camera"
                  />
                </router-link>
              </div>
            </div>
            <div class="offset set-flex">
              <span class="date">{{ date }}</span>
              <div
                v-if="showAnswerBtn || showCancelBtn"
                class="hidden-lg mail-actions"
                @click.prevent="
                  width < 992 ? responsiveAnswer() : showAnswerBtn ? startAnswer() : cancelAnswer()
                "
              >
                <span v-if="showAnswerBtn" :title="$t('mail.utils.reply')" class="rbi rbi-reply" />
                <span v-if="showCancelBtn" :title="$t('mail.utils.cancel')" class="rbi rbi-close" />
              </div>
              <div v-if="detailed" class="mail-actions" @click.stop="closeItem()">
                <i class="rbi rbi-top-arrow" />
              </div>
            </div>
          </div>
        </div>
        <span class="responsive-date date">{{ date }}</span>
        <div
          v-show="!isDialog || detailed"
          v-if="user.user_id !== selfUser"
          class="actions non-click"
        >
          <actions :disabled-props="letter.disabled" :user="user" />
        </div>
        <div
          v-if="letter.attachments.length && !detailed"
          class="attachments"
          :class="{ 'no-display': !isDialog }"
        />
        <div v-if="isDialog" class="msg">
          <p class="msg--heading limited">
            <span>{{ letter.message.subject }}</span>
          </p>
          <div class="msg--msg limited" v-html="letter.message.text" />
          <div v-if="letter.attachments.length" v-show="detailed || !isDialog" class="msg--adds">
            <p class="d-flex align-items-center attach-title">
              <span class="rbi rbi-clip" />
              <span>{{ $tc('mail.utils.attachedImages', letter.attachments.length) }}</span>
            </p>
            <ul class="msg--adds__wrap set-flex list-unstyled non-click">
              <li
                v-for="(photo, i) in letter.attachments"
                :key="photo.id"
                class="msg--adds__wrap--item"
                @click="takePhotos(true, letterAttach, i, true)"
              >
                <div class="image-wrap">
                  <div v-background="{ img: photo.file_name_medium, imgClass: 'obj-fit-cl' }">
                    <img
                      :src="photo.file_name_medium"
                      class="obj-fit-cl"
                      :alt="$tc('mail.utils.attachedImages', 1)"
                    />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="!isDialog" class="msg">
        <p class="msg--heading limited">
          <span>{{ letter.message.subject }}</span>
        </p>
        <div class="msg--msg limited" v-html="letter.message.text" />
        <div v-if="letter.attachments.length" v-show="detailed || !isDialog" class="msg--adds">
          <p class="d-flex align-items-center attach-title">
            <span class="rbi rbi-clip" />
            <span>{{ $tc('mail.utils.attachedImages', letter.attachments.length) }}</span>
          </p>
          <ul class="msg--adds__wrap set-flex list-unstyled non-click">
            <li
              v-for="(photo, i) in letter.attachments"
              :key="photo.id"
              class="msg--adds__wrap--item"
              @click="takePhotos(true, letterAttach, i, true)"
            >
              <div class="image-wrap">
                <div v-background="{ img: photo.file_name_medium, imgClass: 'obj-fit-cl' }">
                  <img
                    :src="photo.file_name_medium"
                    class="obj-fit-cl"
                    :alt="$tc('mail.utils.attachedImages', 1)"
                  />
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!--    <div class="answer-action">
      <a href="#" class="btn btn-success btn-answer visible-lg" @click.prevent="startAnswer"
         v-if="showAnswerBtn">
					<span class="icon">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 611.998 611.998">
							<path d="M601.227 89.127c-6.953-7.082-15.55-10.72-25.418-10.72H95.786c-9.87 0-18.625 3.51-25.726 10.72-6.954 7.146-10.463 15.887-10.463 26.032v137.88h33.198v-87.603l230.89 235.95c3.253 3.317 7.177 4.784 11.663 4.784h1.193c4.493 0 8.417-1.466 11.67-4.784L579.1 165.438v331.975c0 1.213-1.8 3-3.29 3H400.25v33.178h175.56c9.868 0 18.24-3.572 25.417-10.782 7.18-7.146 10.77-15.505 10.77-25.395V115.16c0-10.145-3.67-18.76-10.77-26.033zM579.1 118.16L335.947 366.42 92.795 118.16v-3c0-1.467 1.793-3.573 2.992-3.573h480.02c2.093 0 3.292 1.212 3.292 3.573v3z"/>
							<path d="M363.76 488.16c-3.293-5.103-6.725-11.293-19.74-27.244-13.305-16.334-27.098-30.562-51.74-49.64-24.412-18.886-49.69-34.008-87.03-47.534-18.585-6.764-37.982-12.314-58.023-15.888v-57.998c0-7.465-3.567-12.633-10.47-15.25-7.16-2.743-12.85-1.21-17.642 3.574L5.165 392.135c-6.877 6.892-6.896 16.462 0 23.29l113.95 113.38c3.3 3.254 6.878 4.785 11.363 4.785 1.793 0 3.886-.32 6.28-1.213 6.877-3.572 10.47-8.677 10.47-15.25v-56.53h11.062c24.827 0 50.252 3 75.672 8.678 39.782 8.933 73.778 25.075 106.17 41.282l.6.318c5.98 3.893 14.88 3.255 20.334-1.786 6.215-5.743 6.764-12.826 2.994-20.355l-.3-.574zM158.29 427.74c-13.156 0-23.014.383-29.605 1.148-8.065 1.02-14.656 7.784-14.656 16.46v32.03l-74.173-73.565 74.172-74.204v32.604c0 8.678 5.97 15.377 14.055 16.462 59.504 8.166 112.456 31.71 158.81 70.887-42.77-14.676-85.837-21.822-128.605-21.822z"/>
						</svg>
					</span>
        <span class="hidden-xs">{{$t( 'mail.utils.reply' )}}</span>
      </a>
      <a href="#" class="btn btn-danger cancel visible-lg" @click.prevent="cancelAnswer"
         v-if="showCancelBtn">
				<span class="icon">
					<i class="fa fa-times" aria-hidden="true"></i>
				</span>
        <span class="hidden-xs">
					{{$t( 'mail.utils.cancel' )}}
				</span>
      </a>
    </div>-->
  </div>
</template>

<script>
import moment from 'moment';
import Actions from '../../Shared/parts/UserActions.vue';
import { AGE, PHOTO_SRC, WEBCAM, MINI_PROFILE, GALLERY_INDEX } from '../../../mixins/utils';
import { MAIL_MODAL } from '../../../mixins/formsActions';

export default {
  components: {
    Actions,
  },
  mixins: [AGE, PHOTO_SRC, WEBCAM, MINI_PROFILE, GALLERY_INDEX, MAIL_MODAL],
  props: {
    letter: {
      type: Object,
      required: true,
    },
    selfUser: Number,
    isDialog: Boolean,
    open: Boolean,
    canAnswer: Boolean,
  },
  data() {
    return {
      detailed: false,
      showAnswer: false,
    };
  },
  computed: {
    /**
     * Трансформировать объекты с аттачами писем в соответствии с требованиями галереи
     *
     * @return {array}
     */
    letterAttach() {
      return this.letter.attachments.map((item) => ({
        img_origin: item.file_name_origin,
        id: item.id,
        mail_id: item.mail_id,
      }));
    },

    detailedMessage() {
      if (!this.letter.detailed) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.detailed = false;
        this.$set(this.letter, 'detailed', false);
      }
      return this.letter.detailed;
    },

    /**
     * Показывать ли кнопку с ответом на письмо
     * @return {boolean}
     */
    showAnswerBtn() {
      return (
        this.sex.isWoman &&
        this.canAnswer &&
        this.detailed &&
        this.letter.message.sender_user_id !== this.selfUser &&
        !this.letter.answering &&
        !this.letter.message.replied_message_id
      );
    },

    /**
     * Показывать ли кнопку с отменой ответа на письмо
     *
     * @return {boolean}
     */
    showCancelBtn() {
      return this.sex.isWoman && this.letter.answering;
    },

    /**
     * Получить профиль пользователя
     * @return {object}
     */
    user() {
      return this.letter.sender ? this.letter.sender : this.letter.companion;
    },

    width() {
      return this.$store.getters.deviceWidth;
    },

    /**
     * Отформатировать дату к требуемуему виду
     * @return {string}
     */
    date() {
      return moment(this.letter.message.time_create * 1000).format('MMM Do, LT');
    },
    sex() {
      return this.$store.getters.sex;
    },
  },
  watch: {
    /**
     * Вручную обновить состояния приложения при получении письма
     * @param v {object}
     */
    letter() {
      this.$forceUpdate();
    },
  },
  created() {
    this.$set(this.letter, 'answering', false);
    this.$set(this.letter, 'detailed', false);
  },
  mounted() {
    this.open ? (this.letter.detailed = true) && (this.detailed = true) : '';
  },
  methods: {
    /**
     * Открыть письмо для детального просмотра
     * @param e {object} - event клика
     */
    openItem(e) {
      // eslint-disable-next-line no-undef
      if (this.isDialog && !$(e).closest('.non-click').length && !this.letter.detailed) {
        this.detailed = true;
        this.letter.detailed = true;
        // 		if(!this.letter.message.replied_message_id && this.letter.message.sender_user_id !== this.selfUser && this.sex.isWoman && this.detailed ) {
        //   this.showAnswerBtn = !this.showAnswerBtn
        // }
      }
    },

    /**
     * Закрыть детальный просмотр письма
     */
    closeItem() {
      this.detailed = false;
      this.letter.detailed = false;
    },

    /**
     * Ответить на письмо
     */
    responsiveAnswer() {
      // similar functionality on ResponsiveWriteMailBtn.vue
      this.toggleLoadMailUser(this.user.user_id, undefined, {
        data: {
          targetMessage: this.letter.message.id,
        },
        isAnswer: true,
        womanCanAnswer: true,
      });
    },

    /**
     * Начать ответ на письмо. Посылает событие в родителя, сообщая ему это
     */
    startAnswer() {
      this.$set(this.letter, 'answering', false);
      this.letter.answering = true;
      this.showAnswer = true;
      this.$emit('select2Answer', {
        letter: this.letter,
        el: this.$el,
        receiverID: this.letter.message.sender_user_id,
      });
    },

    /**
     * Отменить ответ на письмо
     */
    cancelAnswer() {
      this.letter.answering = false;
      this.$emit('cancelAnswer');
    },
  },
};
</script>

<style>
.content .no-display {
  display: none;
}
</style>
