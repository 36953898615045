<template>
  <form id="mail-form" class="mail--form" @submit.prevent="sendLetter">
    <h3 v-if="sex.isWoman" class="mail--form__tip">
      {{ tip }}
    </h3>
    <div class="d-flex">
      <div class="mail--form__avatar">
        <div class="img-wrap-circle">
          <img :src="setPhotoSource(ava)" :alt="user.name + $t('shared.alts.ava')" />
        </div>
      </div>
      <div class="mail--form__body">
        <div class="form-group" :class="{ 'has-error': errs.first('dMailSubject') && showError }">
          <input
            v-model="subject"
            class="form-control"
            name="dMailSubject"
            type="text"
            :placeholder="$t('mail.placeholders.subject')"
          />
          <div v-show="errs.first('dMailSubject') && showError" class="error">
            <i class="fa fa-warning" />
            <span>{{ errs.first('dMailSubject') }}</span>
          </div>
        </div>
        <div class="form-group" :class="{ 'has-error': errs.first('dMailMessage') && showError }">
          <content-editable
            v-if="displayTextarea"
            v-model="message"
            class="form-control"
            :placeholder="$t('mail.placeholders.message')"
            :name="'dMailMessage'"
            :style="{ height: 'auto' }"
            @clearText="clearText = $event"
          />
          <p v-if="sex.isWoman" v-show="clearText" class="small text-muted">
            {{ $t('mail.utils.symbCount', clearText.length) }}
          </p>
          <div v-show="errs.first('dMailMessage') && showError" class="error">
            <i class="fa fa-warning" />
            <span>{{ errs.first('dMailMessage') }}</span>
          </div>
        </div>
        <div class="attach-box--photos">
          <div
            v-for="(photo, index) in attachments"
            :key="photo.id"
            class="attach-box--photos__item"
          >
            <div class="image-wrap">
              <img
                class="img-respons"
                :src="photo._photo"
                :alt="userName + $t('shared.alts.photo')"
                @load="photo.loading = false"
              />
              <div v-show="!photo.loading" class="del-btn" @mouseup="unAttachPhoto(index)">
                <span class="rbi rbi-close" />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-start mail--form__submit-wrap">
          <rb-button type="submit" class="btn-black" :disabled="isSending">
            {{ $t('mail.utils.send') }}
          </rb-button>
          <attach-box
            :clear="clear"
            :photos="attachments"
            :user-id="companionId"
            @attached="attachmentsHandler"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { Validator } from 'vee-validate';
import { PHOTO_SRC } from '../../../mixins/utils';
import { LOW_CREDITS_MODAL } from '../../../mixins/formsActions';
import { ATTACHMENTS, CLEAN_HTML } from '../../../mixins/types/mail';
import AttachBox from '../../Shared/parts/AttachPhotosBox.vue';
import RbButton from '../../Button';
import ContentEditable from '../parts/ContentEditable.vue';

export default {
  mailValidator: null,
  components: {
    AttachBox,
    ContentEditable,
    RbButton,
  },
  mixins: [PHOTO_SRC, ATTACHMENTS, LOW_CREDITS_MODAL, CLEAN_HTML],
  props: {
    msgOrUserID: [Number, Boolean],
    companionId: [Number, String],
    womanCanAnswer: Boolean,
    isDialog: Boolean,
  },
  data() {
    return {
      isSending: false,
      message: '',
      subject: '',
      clearText: '',
      showError: true,
      clear: 0,
      errs: [],
      displayTextarea: true,
    };
  },
  computed: {
    /**
     * Определяет тип подсказки
     * @return {string}
     */
    tip() {
      return this.womanCanAnswer ? this.$t('mail.utils.answerOn') : this.$t('mail.utils.createNew');
    },
    sex() {
      return this.$store.getters.sex;
    },
    user() {
      return this.$store.getters.user;
    },

    /**
     * Получает аватарку своего пользователя
     * @return {string}
     */
    ava() {
      return this.user.avatar ? this.user.avatar.img_small : '';
    },
  },
  watch: {
    /**
     * Валидация ввода текста сообщения
     *
     * @param v {string}
     */
    clearText(v) {
      this.mailValidator.validate('dMailMessage', v).catch(() => {});
    },
    /**
     * Валидация ввода темы сообщения
     *
     * @param v {string}
     */
    subject(v) {
      this.mailValidator.validate('dMailSubject', v).catch(() => {});
    },
  },
  created() {
    this.initValidation();
  },
  methods: {
    unAttachPhoto(index) {
      this.attachments.splice(index, 1);
    },

    attachmentsHandler(attach) {
      this.attachments = attach;
    },
    /**
     * Отправка письма по сабмиту формы с проверкой на валидацию
     */
    sendLetter() {
      const self = this;
      this.mailValidator
        .validateAll({
          dMailSubject: self.subject,
          dMailMessage: self.clearText,
        })
        .then((success) => {
          if (success && !this.isSending) {
            self.showError = false;
            this.isSending = true;
            if (self.sex.isMan || !self.womanCanAnswer) {
              self.makeLetter();
            } else if (self.sex.isWoman) {
              self.replyLetter();
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    /**
     * Создание нового письма. Только клиент
     */
    makeLetter() {
      const attachments = this.attachments.map((i) => i.id) || [];
      if (this.msgOrUserID) {
        this.$http
          .post('v1/message/make', {
            access_token: window.localStorage['access-token'],
            target_user_id: this.msgOrUserID,
            message: this.cleanHtml(this.message),
            subject: this.subject,
            attachments: JSON.stringify(attachments),
          })
          .then(
            (r) => {
              if (r.body.status) {
                this.clearData();
                this.isSending = false;
                this.$store.dispatch('getUserBalance');
                this.$store.dispatch('getCounters', {});

                this.$router.push({ name: 'dialog', params: { letter: this.msgOrUserID } });

                const self = this;

                setTimeout(() => {
                  self.$emit('reply', true);
                }, 50);

                this.$store.commit('addAlert', {
                  type: 'success',
                  text: this.$t('alerts.letterSuccess'),
                });
              } else {
                this.isSending = false;
                const { code } = r.body;
                if (code === 10028 || code === 10161 || code === 10025 || !r.body.status) {
                  this.openCreditsModal();
                } else {
                  this.$store.commit('addAlert', {
                    type: 'error',
                    text: this.$t('alerts.someErr'),
                  });
                }
              }
            },
            () => {
              this.isSending = false;
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
          );
      }
    },

    /**
     * Ответ на письмо. Только клиентка
     */
    replyLetter() {
      const attachments = this.attachments.map((i) => i.id) || [];
      if (this.msgOrUserID) {
        this.$http
          .post('v1/message/reply', {
            access_token: window.localStorage['access-token'],
            target_message_id: this.msgOrUserID,
            message: this.cleanHtml(this.message),
            subject: this.subject,
            attachments: JSON.stringify(attachments),
          })
          .then(
            (r) => {
              if (r.body.status) {
                this.$emit('reply', true);
                this.clearData();
                this.isSending = false;
                this.$store.dispatch('getCounters', {});

                if (this.isDialog) {
                  this.$router.push({ name: 'dialog', params: { letter: this.companionId } });
                } else {
                  this.$router.push({ name: 'mail', params: { letter: this.msgOrUserID } });
                }

                this.$store.commit('addAlert', {
                  type: 'success',
                  text: this.$t('alerts.letterSuccess'),
                });
              } else {
                this.isSending = false;
                this.$store.commit('addAlert', {
                  type: 'error',
                  text: this.$t('alerts.someErr'),
                });
              }
            },
            () => {
              this.isSending = false;
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.someErr'),
              });
            }
          );
      }
    },

    /**
     * Очистка состояния компонента
     */
    clearData() {
      this.subject = '';
      this.message = '';
      this.clearText = '';
      this.displayTextarea = false;
      this.attachments = [];
      this.clear++;
      const self = this;
      setTimeout(() => {
        self.errs.clear();
        self.showError = true;
        self.displayTextarea = true;
      }, 0);
    },

    /**
     * Инициализация плагина с валидацией
     */
    initValidation() {
      this.mailValidator = new Validator();
      const titleValidation = this.sex.isMan ? 'max:255' : 'required|min:10|max:255';
      const letterValidation = this.sex.isMan ? 'required|max:5000' : 'required|min:200|max:5000';
      this.mailValidator.attach('dMailSubject', titleValidation, {
        prettyName: this.$t('mail.placeholders.subject'),
      });
      this.mailValidator.attach('dMailMessage', letterValidation, {
        prettyName: this.$t('mail.placeholders.message'),
      });
      this.$set(this, 'errs', this.mailValidator.errorBag);
    },
  },
};
</script>

<style>
iframe {
  width: 100%;
}
</style>
