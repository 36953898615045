<template>
  <ul
    class="list-unstyled ua-items"
    :class="{'user-actions-transparent': !isSlot}"
  >
    <li v-if="show.wink">
      <button
        class="ua-btn"
        :class="{disabled: dis.wink, 'ua-btn-transparent wink':!isSlot}"
        @click="previewSendWink(true, user.user_id)"
      >
        <span class="checked-wrap"><i class="fa fa-check" /></span>
        <template v-if="isSlot">
          <span class="rbi rbi-emoji" />
        </template>
      </button>
    </li>
    <li v-if="show.bookmark">
      <button
        class="ua-btn"
        :class="{disabled: dis.bookmarks, 'ua-btn-transparent bookmark':!isSlot}"
        @click="previewAddBookmark(true, user.user_id)"
      >
        <span class="checked-wrap"><i class="fa fa-check" /></span>
        <template v-if="isSlot">
          <span class="rbi rbi-star" />
        </template>
      </button>
    </li>
    <li v-if="show.chat">
      <button
        class="ua-btn"
        :class="{disabled: dis.chat, 'ua-btn-transparent chat':!isSlot}"
        @click="previewStartChat(true, user.user_id)"
      >
        <template v-if="isSlot">
          <span class="rbi rbi-comment-text" />
        </template>
      </button>
    </li>
    <li v-if="show.message">
      <button
        class="ua-btn"
        :class="{'ua-btn-transparent mail':!isSlot}"
        @click="previewSendMail(true, user)"
      >
        <template v-if="isSlot">
          <span class="rbi rbi-message" />
        </template>
      </button>
    </li>
    <li v-if="show.gift">
      <button
        class="ua-btn"
        :class="{'ua-btn-transparent gift':!isSlot}"
        @click="sex.isMan ? previewSendGift(true, user.user_id) : previewSendVirtualGift(true, user.user_id)"
      >
        <template v-if="isSlot">
          <span class="rbi rbi-gift" />
        </template>
      </button>
    </li>
    <li v-if="show.fav">
      <button
        class="ua-btn"
        :class="{disabled: dis.favs.status || dis.favs.ignored || dis.favs.isFav, 'ua-btn-transparent favorite':!isSlot}"
        @click="previewAddToFavs(true, user.user_id)"
      >
        <span
          v-if="!dis.favs.ignored || dis.favs.tmp"
          class="checked-wrap"
        ><i class="fa fa-check" /></span>
        <template v-if="isSlot">
          <span class="rbi rbi-heart" />
        </template>
      </button>
    </li>
  </ul>
</template>

<script>
import {
  USER_ACTIONS, CHECK_PRIVILEGES, ACTIONS_DATA, COMPARE_DATA,
} from '../../../mixins/userActions';

export default {
  mixins: [USER_ACTIONS, CHECK_PRIVILEGES, ACTIONS_DATA, COMPARE_DATA],
  props: {
    isSlot: {
      type: Boolean,
      default: true,
    },
    showItem: {
      type: Object,
    },
    user: {
      type: Object,
      required: true,
    },
    disabledProps: Object,
  },
  data() {
    return {};
  },
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
    actionsUserId() {
      return this.user.user_id;
    },
    dis() {
      this.canChat(this.user.online);
      return this.disabled;
    },
    show() {
      const itemShow = {
        bookmark: true,
        chat: true,
        fav: true,
        gift: true,
        message: true,
        wink: true,
        ...this.showItem,
      };
      return itemShow;
    },
  },
  watch: {
    /**
       * запуск обработчика с привилегией чата при обновлении привилегий
       */
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../assets/scss/vars";
  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    margin: 0;
    padding: 10px 0;

    .ua-btn-transparent {
      width: 50px;
      height: 50px;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      border: none;
      transition: .3s;
      cursor: pointer;
      -webkit-background-size: contain !important;
      background-size: contain !important;
      outline: none;

      &.disabled {
        opacity: .5 !important;
      }

      &.wink {
        background: url("/static/images/svg/icons/smile_w_outline.svg");

        &:hover {
          background: url("/static/images/svg/icons/smile_w_solid.svg");
        }

        &.disabled {
          &:hover {
            background: url("/static/images/svg/icons/smile_w_outline.svg");
          }
        }
      }

      &.bookmark {
        background: url("/static/images/svg/icons/star_w_outline.svg");

        &:hover {
          background: url("/static/images/svg/icons/star_w_solid.svg");
        }

        &.disabled {
          &:hover {
            background: url("/static/images/svg/icons/star_w_outline.svg");
          }
        }
      }

      &.chat {
        background: url("/static/images/svg/icons/chat_w_outline.svg");

        &:hover {
          background: url("/static/images/svg/icons/chat_w_solid.svg");
        }

        &.disabled {
          &:hover {
            background: url("/static/images/svg/icons/chat_w_outline.svg");
          }
        }
      }

      &.mail {
        background: url("/static/images/svg/icons/mail_w_outline.svg");

        &:hover {
          background: url("/static/images/svg/icons/mail_w_solid.svg");
        }

        &.disabled {
          &:hover {
            background: url("/static/images/svg/icons/mail_w_outline.svg");
          }
        }
      }

      &.gift {
        background: url("/static/images/svg/icons/gift_w_outline.svg");

        &:hover {
          background: url("/static/images/svg/icons/gift_w_solid.svg");
        }

        &.disabled {
          &:hover {
            background: url("/static/images/svg/icons/gift_w_outline.svg");
          }
        }
      }

      &.favorite {
        background: url("/static/images/svg/icons/heart_w_outline.svg");

        &:hover {
          background: url("/static/images/svg/icons/heart_w_solid.svg");
        }

        &.disabled {
          &:hover {
            background: url("/static/images/svg/icons/heart_w_outline.svg");
          }
        }
      }
    }
    .ua-btn{
      background: none;
      border: 1px solid $red;
      font-size: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      color: $red;
      cursor: pointer;
      transition: .2s;
      box-shadow: inset 0 0 1px 1px rgba(226, 25, 25, 0.45);
      position: relative;
      &.disabled{
        box-shadow: inset 0 0 1px 1px rgba($grey, 0.45);
        border: 1px solid $grey;
        color: $grey;
        .checked-wrap{
          display: block;
        }
        &:hover{
          color: $grey;
          border: 1px solid $grey;
          background-color: transparent;
        }
      }
      &:hover{
        background: $red;
        color: $white;
      }
    }
  }
</style>
