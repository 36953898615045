<template>
  <div class="mail--responsive-mail d-md-block d-lg-none">
    <rb-button
      class="btn-red"
      @click.native="toggleLoadMailUser(user, undefined, {data, isAnswer, womanCanAnswer})"
    >
      <span class="rbi rbi-edit" />
    </rb-button>
  </div>
</template>

<script>
import { MAIL_MODAL } from '../../../../mixins/formsActions';
import RbButton from '../../../Button';

// similar functionality on MailInsideItem.vue

export default {
  components: {
    RbButton,
  },
  mixins: [MAIL_MODAL],
  props: {
    user: Number,
    data: Object,
    isAnswer: Boolean,
    womanCanAnswer: Boolean,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
  .mail--responsive-mail {
    .btn-red {
      padding: 0 !important;
      width: 45px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
</style>
