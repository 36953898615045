<template>
  <div>
    <div class="mail--box__header set-flex set-flex-y-center">
      <div class="set-flex mail--responsive-nav hidden-lg">
        <responsive-menu class="menu" style="display: none" />
        <!--        <button type="button"
                        @click="mainClearSelectedMails()"
                        class="btn btn-mail btn-gray undo-select"
                        v-if="showResponsiveCheckedState">
                            <span class="set-flex set-flex-y-center set-flex-x-center">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400.004 400.004"><path
                      d="M382.688 182.686H59.116l77.21-77.214c6.763-6.76 6.763-17.726 0-24.485-6.765-6.764-17.73-6.764-24.485 0L5.074 187.757c-6.764 6.76-6.764 17.727 0 24.485L111.84 319.017c3.382 3.383 7.813 5.072 12.243 5.072 4.43 0 8.86-1.69 12.242-5.073 6.764-6.76 6.764-17.726 0-24.484l-77.21-77.218H382.69c9.562 0 17.316-7.753 17.316-17.315 0-9.562-7.753-17.314-17.316-17.314z"/></svg>
                                <span><strong>{{ checkedMails.length }}</strong></span>
                            </span>
                </button>-->
      </div>
      <div class="action-page--heading">
        <div class="set-flex set-flex-x-between head-wrap">
          <h1 class="action-page--heading__h d-lg-none d-sm-flex d-flex" @click="toogleMenu">
            <span>{{ $t('actionPages.titles.mail') }}</span>
            <span
              v-show="sex.isMan ? counters.mails_inbox_new : counters.mails_inbox_new_not_reply"
              class="count"
              >({{
                sex.isMan ? counters.mails_inbox_new : counters.mails_inbox_new_not_reply
              }})</span
            >
            <mail-sidebar
              v-if="menuOpen"
              v-click-away="closeMenu"
              :heading="false"
              @click.native.stop
            />
          </h1>
          <div class="action-page--tip">
            <div class="left-items d-flex">
              <div class="item">
                <multiselect
                  v-model="sortValue"
                  :class="{ op0: search.open }"
                  :allow-empty="false"
                  :options="sortTypes"
                  :searchable="false"
                  track-by="name"
                  label="text"
                  :show-labels="false"
                />
              </div>
              <div class="item item-search">
                <button class="button-search" type="button" @click="toggleSearch">
                  <span class="rbi rbi-search" />
                </button>
                <div v-show="search.open" class="form-group">
                  <input
                    v-model="searchValue"
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('shared.placeholders.nameId')"
                  />
                </div>
              </div>
            </div>
            <div v-if="view !== 'dialog'" class="right-items">
              <template v-if="checkedMails.length">
                <div v-if="view == 'inbox'" class="item">
                  <button v-if="sex.isMan" type="button" class="item-btn" @click="markLetters">
                    <span class="rbi rbi-mail-envelope-open" />
                  </button>
                </div>
                <div class="item">
                  <button type="button" class="item-btn del" @click="deleteMails">
                    <span class="rbi rbi-trash" />
                  </button>
                </div>
              </template>
              <div v-if="!showResponsiveCheckedState" class="select-type item">
                <multiselect
                  v-model="selectValue"
                  :allow-empty="false"
                  :options="selectTypes"
                  :searchable="false"
                  track-by="value"
                  label="text"
                  :show-labels="false"
                />
              </div>
            </div>
            <div class="responsive-control">
              <button
                v-if="showResponsiveCheckedState"
                type="button"
                class="undo-select"
                @click="mainClearSelectedMails()"
              >
                <span class="rbi rbi-left-arrow" />
                <span
                  ><strong>{{ checkedMails.length }}</strong></span
                >
              </button>
              <template v-else>
                <div class="item">
                  <multiselect
                    v-model="sortValue"
                    :class="{ op0: search.open }"
                    :allow-empty="false"
                    :options="sortTypes"
                    :searchable="false"
                    track-by="name"
                    label="text"
                    :show-labels="false"
                  />
                </div>
                <div class="item item-search" :class="{ 'search-open': search.open }">
                  <button class="button-search" type="button" @click="toggleSearch">
                    <span class="rbi rbi-search" />
                  </button>
                  <div v-show="search.open" class="form-group">
                    <input
                      v-model="searchValue"
                      type="text"
                      class="form-control search-input"
                      :placeholder="$t('shared.placeholders.nameId')"
                    />
                  </div>
                </div>
              </template>

              <div class="item item-check">
                <template v-if="checkedMails.length">
                  <div v-if="view == 'inbox'">
                    <button v-if="sex.isMan" type="button" class="item-btn" @click="markLetters">
                      <span class="rbi rbi-mail-envelope-open" />
                    </button>
                  </div>
                  <div>
                    <button type="button" class="item-btn del" @click="deleteMails">
                      <span class="rbi rbi-trash" />
                    </button>
                  </div>
                </template>
                <div v-if="!showResponsiveCheckedState" class="select-type">
                  <multiselect
                    v-model="selectValue"
                    :allow-empty="false"
                    :options="selectTypes"
                    :searchable="false"
                    track-by="value"
                    label="text"
                    :show-labels="false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="mail-control d-flex align-items-center">
              <div class="mail-control-item d-flex align-items-center" v-if="!showResponsiveCheckedState">
                <div class="item">

                </div>
                <div class="item">
                  <form class="item" @submit.prevent="">
                    <div class="custom-search" :class="{open: search.open}" @submit.prevent="search">

                      <div class="custom-search&#45;&#45;input-wrap">

                      </div>
                    </div>
                  </form>

                </div>
              </div>
              <form class="mail-control-item mail&#45;&#45;box__header&#45;&#45;select-form set-flex" @submit.prevent=""
                    v-if="view !== 'dialog'">
                <template v-if="checkedMails.length">
                  <div v-if="view == 'inbox'">
                    <button type="button" class="btn item" v-if="sex.isMan" @click="markLetters">
                                      <span class="set-flex set-flex-y-center set-flex-x-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 301.109 301.109">
                                              <path
                            d="M158.446 141.68c6.075 0 11 4.924 11 11s-4.925 11-11 11h-15.782c-6.075 0-11-4.926-11-11s4.925-11 11-11h15.782zm26.7-29.88c0-6.075-4.926-11-11-11h-47.177c-6.076 0-11 4.925-11 11s4.924 11 11 11h47.175c6.075 0 11-4.925 11-11zM76.15 98.6c6.075 0 11-4.924 11-11V22H213.96v65.6c0 6.076 4.925 11 11 11s11-4.924 11-11V11c0-6.075-4.925-11-11-11H76.152c-6.075 0-11 4.925-11 11v76.6c0 6.076 4.925 11 11 11zm97.993-38.68H126.97c-6.076 0-11 4.925-11 11s4.924 11 11 11h47.175c6.075 0 11-4.925 11-11s-4.925-11-11-11zm124.08 2.405V290.11c0 6.074-4.924 11-11 11H13.886c-6.076 0-11-4.926-11-11V62.324c0-6.075 4.924-11 11-11h22.67c6.075 0 11 4.925 11 11s-4.925 11-11 11h-11.67v46.335H61.06c4.405 0 8.385 2.628 10.116 6.68l26.338 61.657H203.6l26.336-61.657c1.73-4.05 5.71-6.68 10.116-6.68h36.174V73.326h-11.67c-6.076 0-11-4.925-11-11s4.924-11 11-11h22.67c6.075 0 11 4.925 11 11zm-22 79.336h-28.91l-26.337 61.658c-1.73 4.05-5.71 6.68-10.116 6.68H90.252c-4.405 0-8.385-2.63-10.116-6.68L53.798 141.66H24.884v137.45h251.342V141.66z"/>
                                          </svg>
                                      </span>
                    </button>
                  </div>
                  <div>
                    <button type="button" class="btn item" @click="deleteMails">
                                      <span class="set-flex set-flex-y-center set-flex-x-center">
                                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 489.6 489.6">
                                              <path
                            d="M456.5 75.6h-103V51.5C353.5 23.1 330.4 0 302 0H187.6c-28.4 0-51.5 23.1-51.5 51.5v24.1h-103c-9.9 0-18 8.1-18 18s8.1 18 18 18h27.4v315.2c0 34.6 28.1 62.8 62.8 62.8h243.1c34.6 0 62.8-28.1 62.8-62.8V169.2c0-9.9-8.1-18-18-18s-18 8.1-18 18v257.6c0 14.8-12 26.8-26.8 26.8H123.2c-14.8 0-26.8-12-26.8-26.8V111.6h360c10 0 18.1-8.1 18.1-18s-8.1-18-18-18zm-138.9 0H172.1V51.5c0-8.5 6.9-15.5 15.5-15.5h114.5c8.5 0 15.5 6.9 15.5 15.5v24.1z"/>
                                              <path
                            d="M244.8 151.2c-9.9 0-18 8.1-18 18V396c0 9.9 8.1 18 18 18s18-8.1 18-18V169.2c0-9.9-8.1-18-18-18zM169.2 151.2c-9.9 0-18 8.1-18 18V396c0 9.9 8.1 18 18 18s18-8.1 18-18V169.2c0-9.9-8.1-18-18-18zM320.4 151.2c-9.9 0-18 8.1-18 18V396c0 9.9 8.1 18 18 18s18-8.1 18-18V169.2c0-9.9-8.1-18-18-18z"/>
                                          </svg>
                                      </span>
                    </button>
                  </div>
                </template>
                <div v-if="!showResponsiveCheckedState">
                  <select v-model="selectValue" class="form-control item">
                    <option :value="item.value" v-for="item in selectTypes">{{ item.text }}</option>
                  </select>
                </div>
              </form>
            </div>-->
    </div>
    <div class="mail--box__body">
      <p v-if="search.searching && !showPreloader && !letters.length" class="alert alert-warning">
        {{ $t('actionPages.errors.noSearchResults') }}
      </p>
      <p v-if="!search.searching && !showPreloader && !letters.length" class="alert alert-info">
        {{ $t('mail.errors.noMail') }}
      </p>
      <div>
        <mail-item
          v-for="letter in letters"
          :key="letter.message.id"
          :letter="letter"
          :view="view"
          :width="width"
        />
      </div>
      <div v-show="showLoadBtn && !showPreloader && letters.length" class="button-wrap">
        <lazy-load :counter="defaultLimit" @click.native="loadMore" />
      </div>
      <div v-show="showPreloader" class="loader--wrap">
        <div class="loader" />
      </div>
    </div>
    <responsive-write-mail-btn :user="0" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import MailItem from './MailItem.vue';
import MailSidebar from './MailSidebar.vue';
import RespMenu from './responsive/Menu.vue';
import Load from '../parts/LoadMore.vue';
import ResponsiveWriteMailBtn from './responsive/ResponsiveWriteMailBtn.vue';
import { DELETE_MAILS, MARK_READ } from '../../../mixins/types/mail';
import { PREPARE_DATA } from '../../../mixins/userActions';

export default {
  props: {
    width: Number,
    routeIn: String,
    routeView: String,
  },
  data() {
    return {
      limit: 20,
      defaultLimit: 20,
      showPreloader: false,
      showLoadBtn: true,
      searchValue: '',
      checked: false,
      menuOpen: false,
      search: {
        open: false,
        searching: false,
        result: '',
      },
      sortValue: { name: '', text: this.$t('mail.sort.default') },
      sortTypes: [
        { name: '', text: this.$t('mail.sort.default') },
        { name: 'a-z', text: this.$t('mail.sort.senderAZ') },
        { name: 'z-a', text: this.$t('mail.sort.senderZA') },
        { name: 'asc', text: this.$t('mail.sort.dateASK') },
        { name: 'desc', text: this.$t('mail.sort.dateDESK') },
      ],
      selectValue: { value: '', text: this.$t('mail.select.default') },
      resource: {},
      LETTERS_STORE: {
        inbox: {
          all: [],
          favorites: [],
          bookmarks: [],
        },
        sent: {
          all: [],
          favorites: [],
          bookmarks: [],
        },
        dialog: {
          all: [],
          favorites: [],
          bookmarks: [],
        },
      },
    };
  },
  watch: {
    /**
     * Запустить проверку привилегий пользователя (можно ли винкнуть, добавить в фавориты и пр)
     * @param v {array}
     */
    letters(v) {
      if (!this.checked) {
        this.extendedCheck(v, []);
        this.checked = true;
      }
      this.clearUpdate();
    },
    /**
     * Запустить загрузку писем при обновлении состояния из модалки с написанием письма
     */
    updateMailState() {
      this.clearUpdate();
      this.loadMail({});
    },
    /**
     * Очистить состояние при навигации на странице. Например, между входящими и исходящими письмами
     */
    $route() {
      this.checked = false;
      this.clearUpdate();
      this.loadMail({});
      this.mainClearSelectedMails();
      this.limit = this.defaultLimit;
      this.searchValue = '';
      this.sortValue = { name: '', text: this.$t('mail.sort.default') };
    },
    /**
     * Запустить сортировку писем в зависимости от выбранного значения в селекте (по дате создания, по отправителю)
     *
     * @param val {string}
     */
    sortValue(val) {
      this.clearUpdate();
      this.letters = {
        arr: [],
        view: this.view,
        type: this.type,
      };
      this.loadMail({ sort: val, offset: 0 });
    },
    /**
     * Запустить фильтрацию писем в зависимости от выбранного значения в селекте (прочитанные/новые и пр)
     *
     * @param val {string}
     */
    selectValue(val) {
      this.clearSelected();
      if (val) {
        this.select(val);
      } else {
        this.selectValue = { value: '', text: this.$t('mail.select.default') };
      }
    },
    /**
     * Запустить фильтрацию писем с поиском по имени или ИД
     *
     * @param val {string}
     */
    searchValue(val) {
      this.letters = {
        arr: [],
        view: this.view,
        type: this.type,
      };
      if (val) {
        this.showPreloader = true;
        this.searching(val);
        this.search.searching = true;
      } else {
        this.loadMail({});
      }
    },
  },
  methods: {
    toogleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    closeMenu() {
      this.menuOpen = false;
    },
    /**
     * Загрузить список писем
     *
     * @param sort {string} -  смотреть в переменной @param sortTypes[].name {string}
     * @param offset {number} - с какого элемента загружать
     * @param name_or_id {string | number} - имя или ИД отправителя/получателя
     */
    loadMail({ offset, name_or_id }) {
      if (!offset) {
        this.clearSelected();
        this.selectValue = '';
      }
      this.checked = false;
      this.showPreloader = true;
      this.showLoadBtn = false;
      const { type } = this;
      const { view } = this;
      this.resource
        .save(
          {
            view,
          },
          {
            access_token: window.localStorage['access-token'],
            sort: this.sortValue.name || 'DESC',
            in: type,
            name_or_id: name_or_id || this.searchValue || '',
            limit: this.defaultLimit,
            offset: offset || 0,
          }
        )
        .then(
          (response) => {
            if (response.body.status) {
              const { result } = response.body;
              result.forEach((item) => {
                item.selected = false;

                // code below for users privileges check
                if (item.sender) {
                  // inbox || outbox
                  item.user_id = item.sender.user_id;
                } else {
                  // dialogs
                  item.user_id = item.companion.user_id;
                }
              });

              this.showLoadBtn = response.body.result.length >= this.defaultLimit;

              this.letters = {
                arr: response.body.result,
                view,
                type,
                offset,
              };
            } else {
              this.$store.commit('addAlert', {
                type: 'error',
                text: this.$t('alerts.requestError'),
              });
            }

            this.showPreloader = false;
          },
          () => {
            this.$store.commit('addAlert', {
              type: 'error',
              text: this.$t('alerts.requestError'),
            });
          }
        );
    },
    /**
     * Ленивая подгрузка. Кнопка "загрузить больше"
     */
    loadMore() {
      this.checked = false;
      this.loadMail({ offset: this.letters.length });
    },
    /**
     * Очистить выбранные письма (новые / прочитанные и пр)
     *
     * @param markRead {boolean} - отметить "прочитанным" (без отправки на сервер)
     */
    clearSelected(markRead) {
      const tmp = this.letters.filter((item) => item.selected);
      tmp.forEach((item) => {
        item.selected = false;
      });
      if (markRead) {
        tmp.forEach((item) => {
          item.message.time_open = 1;
        });
      }
    },
    /**
     * Запустить очистку состояния выбранных писем и значение селекта с выборкой
     *
     * @param markRead {boolean} - отметить "прочитанным" (без отправки на сервер)
     */
    mainClearSelectedMails(markRead) {
      this.clearSelected(markRead);
      this.selectValue = '';
    },
    /**
     * Отметить письма как прочитанные
     */
    markLetters() {
      this.markAsRead(this.checkedMails);
      this.mainClearSelectedMails(true);
    },
    /**
     * Удалить выбранные письма
     */
    deleteMails() {
      const self = this;
      this.mailDelete(this.checkedMails).then((response) => {
        response.forEach((item) => {
          const index = self.letters.findIndex((letter) => letter.message.id === item);
          self.letters.splice(index, 1);
        });
      });
    },

    /**
     * Отобразить/спрятать поисковую паренль
     */
    toggleSearch() {
      this.search.open = !this.search.open;
    },

    /**
     * Поиск по ИД или имени отправителя/получателя
     */
    searching: debounce(function (val) {
      if (this.searchValue) this.loadMail({ name_or_id: val });
    }, 300),

    /**
     * Выбрать письма в зависимости от значения селекта
     * @param val {string}
     */
    select(val) {
      switch (val.value) {
        case '':
          break;
        case 'all':
          this.letters.forEach((item) => (item.selected = true));
          break;
        case 'new':
          // eslint-disable-next-line no-case-declarations
          let tmp = [];
          if (this.sex.isMan) {
            tmp = this.letters.filter((item) => item.message.time_open == null);
          } else if (this.sex.isWoman) {
            if (this.routeView !== 'sent') {
              tmp = this.letters.filter((item) => !item.message.replied_message_id);
            } else {
              tmp = this.letters.filter((item) => item.message.time_open == null);
            }
          }
          tmp.forEach((item) => (item.selected = true));
          break;
        case 'read':
          // eslint-disable-next-line no-case-declarations
          let read = [];
          if (this.sex.isMan) {
            read = this.letters.filter((item) => item.message.time_open > 0);
          } else if (this.sex.isWoman) {
            if (this.routeView !== 'sent') {
              read = this.letters.filter((item) => item.message.replied_message_id);
            } else {
              read = this.letters.filter((item) => item.message.time_open > 0);
            }
          }
          read.forEach((item) => (item.selected = true));
          break;
      }
    },
  },
  computed: {
    /**
     * Получить/записать письма, в зависимости от текущего положения навигации (входящие/исходящие/диалоги/букмарки) и пр
     */
    letters: {
      get() {
        return this.LETTERS_STORE[this.view][this.type];
      },
      set(val) {
        if (val.offset) {
          this.LETTERS_STORE[val.view][val.type] = this.LETTERS_STORE[val.view][val.type].concat(
            val.arr
          );
        } else {
          this.LETTERS_STORE[val.view][val.type] = val.arr;
        }
      },
    },

    /**
     * Варианты значений, по которым можно выбирать письма
     * @return {[object,object,object,object]}
     */
    selectTypes() {
      if (this.sex.isMan) {
        return [
          { value: '', text: this.$t('mail.select.default') },
          { value: 'all', text: this.$t('mail.select.all') },
          {
            value: 'new',
            text: this.$t('mail.select.unread'),
          },
          {
            value: 'read',
            text: this.$t('mail.select.read'),
          },
        ];
      }
      if (this.sex.isWoman) {
        return [
          { value: '', text: this.$t('mail.select.default') },
          {
            value: 'read',
            text:
              this.routeView !== 'sent'
                ? this.$t('mail.select.answered')
                : this.$t('mail.select.read'),
          },
        ];
      }
      return [];
    },

    /**
     * Получить выбранные (отмеченные) письма
     * @return {array}
     */
    checkedMails() {
      return this.letters.filter((item) => item.selected).map((item) => item.message.id);
    },

    /**
     * Получить тип требуемых писем для загрузки в зависимости от @param $route
     *
     * @return {string}
     */
    type() {
      return this.routeIn || 'all';
    },

    /**
     * Получить тип требуемых писем для загрузки в зависимости от @param $route
     *
     * @return {string}
     */
    view() {
      return this.routeView || 'inbox';
    },

    /**
     * Показывать ли отмеченное состояние на респонзиве
     * @return {Number|boolean}
     */
    showResponsiveCheckedState() {
      return this.checkedMails.length && this.width < 768 && this.width !== 0;
    },
    selfUserId() {
      return this.$store.getters.user.user_id;
    },
    /**
     * Геттер с флагом о состоянии писем
     * @return {boolean}
     */
    updateMailState() {
      return this.$store.getters.updateMailState;
    },
    sex() {
      return this.$store.getters.sex;
    },
    counters() {
      return this.$store.getters.counters;
    },
  },
  components: {
    mailItem: MailItem,
    lazyLoad: Load,
    responsiveMenu: RespMenu,
    ResponsiveWriteMailBtn,
    MailSidebar,
  },
  mounted() {
    this.loadMail({});
  },
  mixins: [DELETE_MAILS, MARK_READ, PREPARE_DATA],
  created() {
    this.resource = this.$resource('v1/message/load{/view}');
  },
};
</script>

<style scoped>
.loader--wrap {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 100px;
}
</style>
