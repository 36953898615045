<template>
  <div class="main-block">
    <div class="mail white-box">
      <div class="mail--inner clearfix row no-gutters">
        <mail-sidebar class="d-none d-md-none d-lg-block" />
        <div class="mail--box col-12 col-md-12 col-lg-9">
          <router-view :width="utils.width" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MailSidebar from './Mail/MailSidebar.vue';

export default {
  components: {
    MailSidebar,
  },
  data() {
    return {
      utils: {
        width: 0,
      },
    };
  },
  computed: {
    sex() {
      return this.$store.getters.sex;
    },
  },
  mounted() {
    document.title = this.$t('actionPages.titles.mail');
    const self = this;
    // eslint-disable-next-line no-undef
    $(window).resize(() => {
      // eslint-disable-next-line no-undef
      self.utils.width = $(window).width();
    });
  },
};
</script>

<style scoped>
.main-block {
  z-index: auto;
}
</style>
