<template>
  <div class="custom-checkbox">
    <input
      :id="id"
      :name="name"
      :checked="shouldBeChecked"
      :value="value"
      class="inp-cbx"
      type="checkbox"
      style="display: none"
      @change="updateInput"
    />
    <label class="cbx" :for="id">
      <span>
        <svg width="12px" height="10px" viewbox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1" />
        </svg>
      </span>
      <span v-if="hasDefaultSlot"><slot /></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomCheckbox',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    name: {
      type: String,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    value: {
      type: [Boolean, Number],
    },
    modelValue: {
      default: false,
    },
    trueValue: {
      default: true,
    },
    falseValue: {
      default: false,
    },
  },
  computed: {
    hasDefaultSlot() {
      return !!this.$slots.default;
    },

    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;

      if (this.modelValue instanceof Array) {
        const newValue = [...this.modelValue];

        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }

        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style scoped></style>
