<template>
  <div class="mail--box__body--item mail--box__body--single detailed response">
    <div class="mail--box__body--item__inner">
      <div class="content">
        <div class="d-flex align-items-center justify-content-between msg-top">
          <div class="set-flex">
            <p class="response-header">{{ $t('mail.utils.response') }}:</p>
          </div>
          <div class="offset set-flex">
            <span class="date">{{ date }}</span>
          </div>
        </div>
        <div class="msg">
          <p class="msg--heading limited">
            {{ letter.message_reply.subject }}
          </p>
          <p class="msg--msg limited" v-html="letter.message_reply.text" />
          <div v-if="letter.message_reply_attachments.length" class="msg--adds">
            <p class="d-flex align-items-center attach-title">
              <span class="rbi rbi-clip" />
              <span>{{
                $tc('mail.utils.attachedImages', letter.message_reply_attachments.length)
              }}</span>
            </p>
            <ul class="msg--adds__wrap d-flex list-unstyled non-click">
              <li
                v-for="(photo, i) in letter.message_reply_attachments"
                :key="photo.id"
                class="msg--adds__wrap--item"
                @click="takePhotos(true, letterAttach, i, true)"
              >
                <div
                  v-background="{ img: photo.file_name_medium, imgClass: 'obj-fit-cl' }"
                  class="image-wrap"
                >
                  <img
                    :src="photo.file_name_medium"
                    class="obj-fit-cl"
                    :alt="$tc('mail.utils.attachedImages', 1)"
                  />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { GALLERY_INDEX } from '../../../mixins/utils';

export default {
  mixins: [GALLERY_INDEX],
  props: {
    letter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    /**
     * Получить дату в соответствии с желаемым видом
     * @return {string}
     */
    date() {
      return moment(this.letter.message.time_create * 1000).format('MMM Do, LT');
    },

    /**
     * Трансформировать аттачи письма в соответствии с требованиями галереи
     */
    letterAttach() {
      return this.letter.message_reply_attachments.map((item) => ({
        img_origin: item.file_name_origin,
        id: item.id,
        mail_id: item.mail_id,
      }));
    },
  },
};
</script>

<style scoped>
.attach-title {
  margin-bottom: 25px;
}
.attach-title .rbi {
  font-size: 22px;
  margin-right: 10px;
  display: inline-block !important;
}
.msg--adds {
  border-top: 1px solid #d9d9d9;
  padding-top: 25px;
  margin-top: 25px;
}
</style>
