<template>
  <div
    v-show="user.info.user_id"
    class="mail--box__body--item list-item"
    :class="{unread: isUnread}"
  >
    <router-link
      tag="div"
      :to="link"
      class="mail--box__body--item__inner clearfix"
      :class="{unread: !letter.message.time_open}"
      @click.native="markAsRead(letter.message.id)"
    >
      <div class="ava-box">
        <div class="ava-wrap">
          <img
            :src="user.info.avatar.img_medium || ''"
            :alt="user.info.name + $t('shared.alts.ava')"
          >
          <span
            v-if="user.info.online"
            class="online"
          />
          <span
            v-if="userWebcam(user.info.camera).status"
            class="webcam rbi-webcam"
            :class="{hd: userWebcam(user.info.camera).hd}"
          />
          <span
            v-if="user.info.new"
            class="is-new"
          />
        </div>
      </div>
      <div class="content">
        <div class="msg-top">
          <div class="d-flex align-items-start justify-content-between">
            <div class="user-info">
              <span class="uid hidden-xs">ID: {{ user.info.user_id }}</span>
              <span class="name">{{ user.info.name }}<template
                v-if="user.info.age !== null"
              >, {{ getUserAge( user.info.age ) }}</template> <span
                v-if="user.attachments"
                class="rbi rbi-camera"
              /></span>
            </div>
            <div
              class="offset set-flex"
              :class="{'with-checkbox': !isDialog}"
            >
              <span
                class="date"
                :class="{'sent-fml': view === 'sent' && isUnread && sex.isWoman}"
              >{{ date }}</span>
              <custom-checkbox
                v-if="!isDialog"
                :id="`user-${user.message.id}`"
                v-model="checked"
                @click.native.stop="selectItem"
              />
            </div>
          </div>
        </div>
        <div class="msg">
          <p class="msg--heading limited">
            {{ letter.message.subject }}
          </p>
          <div
            class="msg--msg limited"
            v-html="letter.message.text"
          />
          <span
            v-if="user.attachments"
            class="rbi rbi-camera"
          />
        </div>
      </div>
      <div
        class="responsive-date"
        :class="{unread: isUnread}"
      >
        <span
          class="date"
          :class="{'sent-fml': view === 'sent' && isUnread && sex.isWoman}"
        >{{ date }}</span>
      </div>
    </router-link>
    <div class="actions">
      <actions
        :disabled-props="letter.disabled"
        :user="user.info"
      />
    </div>
    <!--    <div class="d-lg-none mail&#45;&#45;box__body&#45;&#45;item__checked" v-if="checked" @click="checked = false">
			<span class="set-flex checked-icon">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448.8 448.8"><path
          d="M142.8 323.85L35.7 216.75 0 252.45l142.8 142.8 306-306-35.7-35.7"/></svg>
			</span>
    </div>-->
  </div>
</template>

<script>
import moment from 'moment';
import Actions from '../../Shared/parts/UserActions.vue';
import customCheckbox from '../../Shared/parts/CustomCheckbox';
import {
  AGE, PHOTO_SRC, WEBCAM, MINI_PROFILE,
} from '../../../mixins/utils';
import { MARK_READ } from '../../../mixins/types/mail';

export default {
  components: {
    Actions,
    customCheckbox,
  },
  mixins: [AGE, PHOTO_SRC, WEBCAM, MINI_PROFILE, MARK_READ],
  props: {
    letter: {
      type: Object,
      required: true,
    },
    view: String,
    width: Number,
  },
  data() {
    return {};
  },
  computed: {
    sex() {
      return this.$store.getters.sex;
    },

    /**
       * Запретить выбор для удаления неотвеченного письма у клиентки
       */
    disableCheckbox() {
      return this.view === 'inbox' && this.isUnread && this.sex.isWoman;
    },

    /**
       * Выбрать/устанвоить письмо как "отмеченное"
       */
    checked: {
      get() {
        return this.letter.selected;
      },
      set(val) {
        this.letter.selected = val;
      },
    },

    /**
       * Трансформировать данные к единому виду (разный ответ от сервера приходит)
       * @return {object}
       */
    user() {
      return {
        attachments: this.letter.count_attachments,
        info: this.letter.sender || this.letter.companion,
        message: this.letter.message,
      };
    },

    /**
       * Отформатировать дату
       *
       * @return {string}
       */
    date() {
      return moment(this.user.message.time_create * 1000).format('MMM Do, LT');
    },

    /**
       * Получить ссылку для экземпляра письма
       *
       * @return {object}
       */
    link() {
      if (this.isDialog) {
        return { name: 'dialog', params: { letter: this.letter.companion.user_id } };
      }
      return { name: 'mail', params: { letter: this.user.message.id } };
    },

    /**
       * Узнать, находимся ли мы в "диалогах"
       * @return {boolean}
       */
    isDialog() {
      return this.view === 'dialog';
    },

    /**
       * Узнать, является ли письмо непрочитанным
       * @return {boolean|number}
       */
    isUnread() {
      if (this.isDialog) {
        return this.letter.is_new_dialog;
      } if (this.sex.isWoman) {
        if (this.view !== 'sent') {
          return !this.letter.message.replied_message_id;
        }
        return !this.letter.message.time_open;
      }
      return !this.letter.message.time_open;
    },
    responsiveChecked() {
      return this.width < 1200 && this.checked;
    },
  },
  methods: {
    selectItem() {
      if (this.disableCheckbox) {
        this.$store.commit('addAlert', {
          type: 'error',
          text: this.$t('alerts.selectDisabledMail'),
        });
        this.checked = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .sent-readed-icon {
    svg {
      fill: #05ca6b;
    }
  }

  .content .attachments {
    display: none;
  }
</style>
