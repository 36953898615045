<template>
  <div
    class="mail--responsive-menu"
    :class="{open}"
  >
    <button
      class="btn mail--btn-sidebar"
      @click="open = !open"
    >
      <span class="set-flex">
        <span class="icon unread">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 489.2 489.2"
          ><path
            d="M428 431c33.6 0 60.9-27.3 60.9-60.9v-251c0-33.6-27.3-60.9-60.9-60.9H61c-33.7 0-61 27.3-61 60.9v251c0 34 27.3 61 60.9 61h367zM62 143c-6.3-5.4-7.1-14.8-1.7-21.1s14.8-7.1 21.1-1.7l163 138 163-138c6.3-5.4 15.8-4.6 21.1 1.7 5.4 6.3 4.6 15.8-1.7 21.1l-116 98.8 117 110c6 5.7 6.3 15.2.6 21.2-3 3.1-6.9 4.7-10.9 4.7-3.7 0-7.4-1.4-10.3-4.1l-120-113-33.3 28.3c-2.8 2.4-6.3 3.6-9.7 3.6-3.5 0-6.9-1.2-9.7-3.6l-33.4-28.3-120 113c-2.9 2.7-6.6 4.1-10.3 4.1-4 0-8-1.6-10.9-4.7-5.7-6-5.4-15.5.6-21.2l117-110-116-99z"
          /></svg>
        </span>
      </span>
    </button>
    <mail-sidebar
      class="mail--responsive-menu__menu"
      :tab-suffix="'-r'"
      @close="close"
    />
  </div>
</template>

<script>
import MailSidebar from '../MailSidebar.vue';

export default {
  components: {
    MailSidebar,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {},
  methods: {
    /**
			 * 	Закрыть сайдбар
			 */
    close() {
      this.open = false;
    },
  },
};
</script>

<style>
</style>
